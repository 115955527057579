import { Tooltip } from '@equitymultiple/react-eui';
import React from 'react';
import superagent from 'superagent';

import utils from '../../utilities/utils';
import { debtOfferingTypes, prefEquityOfferingTypes } from './constants';
import * as styles from './containers/AccountShared.module.scss';

export const getInvestmentFundingData = investment => {
  const data = {
    fundedAmount: 0,
    fundingPayments: [],
    unfundedAmount: 0
  };

  investment.payments.forEach(payment => {
    // Increase or decrease total funded amount based on transaction type
    if (
      [
        'Capital Call',
        'Dividend Reinvestment',
        'Investment',
        'Origination Fee'
      ].includes(payment.type)
    ) {
      data.fundedAmount += payment.amount;
      // Add contributions to funding data
      data.fundingPayments.push({
        amount: payment.amount,
        date: payment.date
      });
    }
    if (payment.type === 'Refund') data.fundedAmount -= payment.amount;
  });

  data.unfundedAmount = investment.amount - data.fundedAmount;

  return data;
};

// If an investment has an unfunded balance display a tooltip with details, otherwise return the formatted amount
export const getFormattedAmount = investment => {
  const investmentFundingData = getInvestmentFundingData(investment);
  const amountFormatted = utils.formatCurrency(investment.amount, 0);
  let amount: React.ReactNode | string = amountFormatted;
  if (investmentFundingData.unfundedAmount > 0) {
    amount = (
      <div className={styles.fundInvested}>
        {amountFormatted}
        <Tooltip
          className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
          infoIcon
          placement={window.innerWidth > 767 ? 'right' : 'bottom'}
          tooltipClassName={styles.fundInvestedTooltip}
          tooltipContent={
            <div className="flex">
              <div className={styles.amounts}>
                <div className={styles.fundInvestedLabel}>Unfunded</div>
                <div className={styles.fundInvestedValue}>
                  {utils.formatCurrency(
                    investmentFundingData.unfundedAmount,
                    0
                  )}
                </div>
                <span className={styles.operator}>=</span>
                <div className={styles.fundInvestedLabel}>Committed</div>
                <div className={styles.fundInvestedValue}>
                  {amountFormatted}
                </div>
                <span className={styles.operator}>-</span>
                <div className={styles.fundInvestedLabel}>Funded</div>
                <div
                  className={`${styles.fundInvestedValue} ${styles.marginNone}`}
                >
                  {utils.formatCurrency(investmentFundingData.fundedAmount, 0)}
                </div>
              </div>
              <div className={styles.contributionDates}>
                <div
                  className={`${styles.fundInvestedLabel} ${styles.contributions}`}
                >
                  Contributions
                </div>
                {investmentFundingData.fundingPayments.map((payment, index) => (
                  // eslint-disable-next-line
                  <div className={styles.dateGroup} key={index}>
                    <span className={styles.date}>
                      {utils.dateFormat(payment.date)}
                    </span>
                    <span className={styles.amount}>
                      {utils.formatCurrency(payment.amount, 0)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          }
        />
      </div>
    );
  }

  return amount;
};

// Returns true if the closing is in an 'active' stage
export const isActiveClosing = stage =>
  ['active', 'interest', 'limited_access', 'waitlist'].includes(stage);

export const getAccountDescription = account =>
  account
    ? `(...${account.reference_id?.substr(5, 9)} ${utils.capitalize(
        account.type
      )})`
    : null;

export const getSimplifiedOfferingType = offeringType => {
  if (debtOfferingTypes.includes(offeringType)) return 'Debt';
  if (prefEquityOfferingTypes.includes(offeringType)) return 'Preferred Equity';
  if (offeringType === 'Equity') return 'Equity';
  return null;
};

export const documentDownload = documentIds =>
  superagent
    .get(`/mkt/documents/download?ids=${documentIds}`)
    .responseType('blob');

export const getPaymentStatusDescription = paymentStatus => {
  let description: React.ReactNode | string = (
    <span>
      There is an issue with payments for this investment. Please refer to your
      My Activity page for details from our Asset Management Team.
      <br />
      <br />
      EQUITYMULTIPLE calculates returns (annualized) using the effective date of
      the most recent distribution. For investments where the payment status is
      “unavailable” the annualized return will be recalculated when the
      investment resumes distributions. Please see the my activity page for more
      detailed information on investments with this payment status
    </span>
  );
  if (paymentStatus === 'On Time') {
    description =
      'Your payment is expected to arrive on or before the Next Distribution Date.';
  } else if (paymentStatus === 'Delayed') {
    description =
      'Your payment is expected to arrive after the Next Distribution Date due to a delay in receiving Earnings from the underlying investment.';
  }
  return description;
};

export const getActiveInvestmentNameCell = investment => {
  const hasDrip =
    investment.offering.has_drip &&
    investment.offering.campaign === 'debt_fund';

  const isStnReinvestment = investment.offering.campaign === 'alpine_note';
  const dripOptIn =
    investment.offering.campaign === 'debt_fund' && investment.drip_opt_in;
  const paymentStatus = investment.offering.payment_status;
  const reinvestmentOptOut = investment.reinvestment_option === 'opt_out';

  let targetPaymentTitle = `${
    investment.closing.stage === 'funded' ? 'First' : 'Next'
  } Target ${investment.offering.distribution_frequency} Payment:`;
  if (isStnReinvestment) {
    targetPaymentTitle = reinvestmentOptOut
      ? 'Next Payment:'
      : 'Reinvestment Date:';
  }

  const paymentString = (
    <div data-testid="expectedPaymentDate">
      {targetPaymentTitle}{' '}
      <span className={styles.date}>
        {utils.dateFormat(investment.closing.next_payment_on) || 'TBD'}
        {hasDrip && !dripOptIn && (
          <Tooltip
            className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
            infoIcon
            placement="top"
            tooltipClassName="tooltip-has-icon"
            tooltipContent="As a reminder, only elections made 30 days or more prior to this date will receive a distribution payment on the date shown. All others will receive distribution payments next quarter. "
          />
        )}
      </span>
    </div>
  );
  const paymentStatusInfo = getPaymentStatusDescription(paymentStatus);

  let paymentIcon;
  let paymentColor;
  switch (paymentStatus) {
    case 'Delayed':
      paymentIcon = 'fa-minus-circle';
      paymentColor = 'yellow';
      break;

    case 'On Time':
      paymentIcon = 'fa-check';
      paymentColor = 'green';
      break;

    case 'Payment Unavailable':
      paymentIcon = 'fa-close';
      paymentColor = 'orange';
      break;
  }

  return (
    <div key="offering-name">
      <div className={styles.offeringName}>{investment.offering.title}</div>

      <div className={styles.paymentDetails}>
        {hasDrip && dripOptIn ? (
          <div className="text-green">Dividend Reinvested</div>
        ) : (
          <>
            <span className={styles.paymentStatus} key={investment.offering.id}>
              <span className={`text-${paymentColor}`}>{paymentStatus}</span>
              <Tooltip
                className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
                infoIcon
                placement="top"
                tooltipClassName="tooltip-has-icon"
                tooltipContent={
                  <div>
                    <div className="content">{paymentStatusInfo}</div>
                    <div className={`tooltip-icon ${paymentColor}`}>
                      <i className={`fa ${paymentIcon}`} />
                    </div>
                  </div>
                }
              />
            </span>
            {paymentString}
          </>
        )}
      </div>
    </div>
  );
};

export const formatPerformanceStatus = performanceStatus => {
  if (performanceStatus === 'nonperforming') return 'Non-performing';
  else return utils.capitalize(performanceStatus);
};

export const formatExitedPerformanceStatus = performanceStatus => {
  switch (performanceStatus) {
    case 'nonperforming':
      return 'Did not perform';
    case 'overperforming':
      return 'Overperformed';
    case 'performing':
      return 'Performed';
    case 'underperforming':
      return 'Underperformed';
  }
  return '';
};
