import {
  AttachmentList,
  Card,
  CollapseButton
} from '@equitymultiple/react-eui';
import Collapse from '@mui/material/Collapse';
import { getTaxDocStepInfo } from 'containers/UserTaxTracker/helpers';
import dompurify from 'dompurify';
import step1Mobile from 'images/tax-doc-steps/step-1-mobile.svg?url';
import step1 from 'images/tax-doc-steps/step-1.svg?url';
import step2Mobile from 'images/tax-doc-steps/step-2-mobile.svg?url';
import step2 from 'images/tax-doc-steps/step-2.svg?url';
import step3Mobile from 'images/tax-doc-steps/step-3-mobile.svg?url';
import step3 from 'images/tax-doc-steps/step-3.svg?url';
import step4Mobile from 'images/tax-doc-steps/step-4-mobile.svg?url';
import step4 from 'images/tax-doc-steps/step-4.svg?url';
import step5Mobile from 'images/tax-doc-steps/step-5-mobile.svg?url';
import step5 from 'images/tax-doc-steps/step-5.svg?url';
import moment from 'moment';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Post } from 'types/actions/posts';
import utils from 'utilities/utils';

import * as styles from './PostCard.module.scss';

const stepImages = {
  1: step1,
  '1-mobile': step1Mobile,
  2: step2,
  '2-mobile': step2Mobile,
  3: step3,
  '3-mobile': step3Mobile,
  4: step4,
  '4-mobile': step4Mobile,
  5: step5,
  '5-mobile': step5Mobile
};

type Props = {
  className?: string;
  loading?: boolean;
  openByDefault?: boolean;
  post?: Post;
};

const PostCard = ({
  className,
  loading = false,
  openByDefault,
  post
}: Props) => {
  const [open, setOpen] = useState(openByDefault);

  const handleToggle = () => {
    if (!loading) {
      setOpen(!open);
    }
  };

  const offeringTaxStatus = post?.offering_tax_status;
  const creator = post?.creator;

  const attachments = post?.data?.attachments;
  let titleHasAlert = false;
  let taxDocStep = null;
  let taxDocStepMessage = null;

  if (offeringTaxStatus?.step && offeringTaxStatus.document_type === 'K-1') {
    titleHasAlert =
      offeringTaxStatus.estimate ||
      offeringTaxStatus.no_estimate ||
      offeringTaxStatus.under_review;
    taxDocStep = getTaxDocStepInfo(offeringTaxStatus);
    taxDocStepMessage = getTaxDocStepInfo(offeringTaxStatus, 'message');
  }
  const postDate =
    post && moment(new Date(post.post_date)).format('MM/DD/YYYY h:mm A');

  return (
    <Card className={`${className || ''} ${styles.postCard}`}>
      <div>
        <button
          className={styles.postCardTop}
          disabled={loading}
          onClick={handleToggle}
          type="button"
        >
          <div
            className={`${styles.titleBlock} ${loading ? styles.loading : ''}`}
          >
            <h4
              className={`${styles.postTitle} ${
                titleHasAlert ? styles.hasAlert : ''
              }`}
            >
              {loading ? <Skeleton /> : post.title}
            </h4>
            <div className={styles.postOffering}>
              {loading ? <Skeleton /> : post.offering?.title}
            </div>
          </div>
          <div className={styles.date}>{loading ? <Skeleton /> : postDate}</div>
          {!loading && (
            <CollapseButton
              className={styles.collapseButton}
              onClick={handleToggle}
              open={open}
            />
          )}
        </button>

        <Collapse in={open} timeout="auto" unmountOnExit>
          {loading ? (
            <Skeleton count={4} />
          ) : (
            <div
              className={styles.postBody}
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(post.data.rich_text.content)
              }}
            />
          )}

          {taxDocStep && (
            <div className={styles.taxDocStep}>
              <img
                alt={taxDocStepMessage}
                className={styles.desktop}
                src={stepImages[taxDocStep]}
              />
              <img
                alt={taxDocStepMessage}
                className={styles.mobile}
                src={stepImages[`${taxDocStep}-mobile`]}
              />
            </div>
          )}
          <div className={styles.postAuthor}>
            <img
              alt={creator?.name}
              className={styles.image}
              src={creator?.avatar}
            />
            <div className={styles.imageContent}>
              <div>{creator?.name}</div>
              <div>{creator?.title}</div>
            </div>
          </div>
          {attachments?.length > 0 && (
            <AttachmentList
              attachments={attachments.map(attachment => ({
                filename: attachment.filename.replace(/_/g, ' '),
                id: attachment.id.toString(),
                url: attachment.url
              }))}
            />
          )}
        </Collapse>

        <div className={styles.postTags}>
          {post?.label.split(',').map(label => (
            <b className={`${styles[label]} ${styles.badge}`} key={label}>
              {utils.snakeCaseToTitleCase(label)}
            </b>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default PostCard;
