import { OfferingTaxStatus } from 'types/actions/offering-tax-stauses';

export const getTaxDocStepInfo = (doc, type?) => {
  const step = doc.step;
  const docType = doc.document_type;
  let stepNumber = 0;
  let message = '';
  switch (step) {
    case 'initial_data_preparation':
      stepNumber = 1;
      message = 'Initial data preparation.';
      break;
    case 'preparing_investor_tax_document':
      stepNumber = 4;
      message = 'Preparing investor tax documents.';
      break;
    case 'tax_document_available':
      stepNumber = 5;
      message = 'Tax Document Available.';
      break;
    case 'tax_document_received_and_under_review':
      stepNumber = 3;
      message = 'Tax document received and under review.';
      break;
    case 'tax_document_requested':
      stepNumber = 2;
      message = 'Tax document requested.';
      break;
  }
  // Separate messages for 1099 docs
  if (docType.includes('1099')) {
    message =
      step === 'tax_document_available'
        ? 'Your tax document is now available for download.'
        : 'We are processing your tax document.';
    stepNumber = step === 'tax_document_available' ? 2 : 1;
  }
  return type === 'message' ? message : stepNumber;
};

export const getStnDocuments = (documents: OfferingTaxStatus[]) =>
  documents.filter(doc => doc.is_stn);

export const getNonStnDocuments = (documents: OfferingTaxStatus[]) =>
  documents.filter(doc => !doc.is_stn);

export const getFirstStnDocument = (documents: OfferingTaxStatus[]) =>
  documents.find(document => document.first_stn_offering) ||
  getStnDocuments(documents)[0];
